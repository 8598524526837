<style scoped>
.date-picker {
  width: 150px; /* Atur sesuai kebutuhan */
}
</style>

<template lang="pug">
div
  device-form-modal(
    ref="form"
    @close="showAdd=false"
    :visible="showAdd"
    :currentDevice="currentDevice"
    @onSave="onDeviceChanged"
    @onDelete="onDeviceDelete"
  )
  card(title='Reports')
    div.row
      .col-12
        .d-flex.justify-content-end.mb-5
          matador-search#search-box(placeholder='Cari Nomor Polisi, Nama Perangkat ...' autocomplete='off').mr-2
          input#date-picker(type="date" v-model="selectedDate" class="form-control mr-2 date-picker" @change="onDateChange")
          matador-button.py-2.px-4.ml-2(icon="fa fa-sign-in" :outline="true" @click="onExport") Export
        keep-alive
          .table-responsive
            bs-table(
              ref='table'
              :columns="table.columns",
              :options="table.options",
              :data="devices", 
              @on-post-body="vueFormatterPostBody"
            )
        // Pagination control
        div.d-flex.justify-content-end.mt-4
          button.btn.btn-primary.mr-2(@click="prevPage" :disabled="currentPage === 1") Previous
          span Page {{ currentPage }} of {{ totalPages }}
          button.btn.btn-primary.ml-2(@click="nextPage" :disabled="currentPage === totalPages") Next
</template>

<script>
import tableMixin from "@/mixins/table";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import Dropdown from "@/components/Dropdown.vue";
import axios from "axios";

export default {
  mixins: [tableMixin],

  components: {
    ChevronDown,
    ChevronUp,
    EyeOutline,
    EyeOffOutline,
    Plus,
  },

  data() {
    return {
      currentDevice: {},
      selectedDate: this.getTodayDate(), // Set default date ke hari ini
      allDevices: [], // Menyimpan semua data dari server
      devices: [], // Menyimpan data yang sudah dipaginate
      currentPage: 1, // Halaman saat ini
      perPage: 10, // Jumlah data per halaman
      totalPages: 0, // Total halaman
      table: {
        options: {
          search: true,
          pagination: false, // Pagination di-backend dinonaktifkan
          searchSelector: "#search-box",
          sortable: true,
          sortDesc: true,
          classes: ["table", "table-hover"],
          loadingFontSize: 16,
        },
        columns: [
          {
            title: "Nomor Polisi",
            field: "nomorpolisi",
            sortable: true,
            sortDesc: true,
            searchable: true,
            widthUnit: "%",
            width: "22",
          },
          {
            title: "Nama Perangkat",
            field: "namaperangkat",
            sortable: true,
            sortDesc: true,
            searchable: true,
            widthUnit: "%",
            width: "22",
          },
          {
            title: "Jenis Kendaraan",
            field: "jeniskendaraan",
            sortable: true,
            sortDesc: true,
            searchable: true,
            widthUnit: "%",
            width: "22",
          },
          {
            title: "Jam",
            field: "hour",
            sortable: true,
            sortDesc: true,
            searchable: true,
            widthUnit: "%",
            width: "22",
          },
          {
            title: "Bahan bakar",
            field: "fuel",
            sortable: true,
            sortDesc: true,
            searchable: true,
            widthUnit: "%",
            width: "22",
            formatter: (value) => {
              return `${value}`;
            },
          },
          {
            title: "Jarak Ditempuh",
            field: "distance",
            sortable: true,
            sortDesc: true,
            searchable: true,
            widthUnit: "%",
            width: "22",
            formatter: (value) => {
              return `${value}`;
            },
          },
        ],
      },
    };
  },

  methods: {
    getTodayDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    onDateChange() {
      this.fetchDeviceList();
    },
    async fetchDeviceList() {
      try {
        const response = await axios.get(
          `https://api-my.matadortracker.com/v3/vehicle/report_list`,
          {
            params: {
              date: this.selectedDate,
            },
          }
        );

        this.allDevices = response.data.result.data;
        this.totalPages = Math.ceil(this.allDevices.length / this.perPage);
        this.paginateDevices();
      } catch (error) {
        console.error("Failed to fetch device list:", error);
        this.$toast.error("Failed to load device list.");
      }
    },
    paginateDevices() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      this.devices = this.allDevices.slice(start, end);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.paginateDevices();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.paginateDevices();
      }
    },
    clickAdd() {
      this.currentDevice = null;
      this.showAdd = true;
    },
    clickEdit(device) {
      this.currentDevice = device;
      this.showAdd = true;
    },
    clickDelete(device) {
      this.currentDevice = device;
      this.$refs.form.delete();
    },
    async onExport() {
      try {
        const date = this.selectedDate;
        const url = `https://api-my.matadortracker.com/v3/vehicle/export?date=${date}`;

        const response = await axios.get(url, {
          responseType: "blob",
        });

        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Report-${date}.xlsx`;
        link.click();
      } catch (error) {
        console.error("Export failed:", error);
        alert("Export gagal. Silakan coba lagi.");
      }
    },
    onDeviceChanged(device) {
      this.showAdd = false;
      this.fetchDeviceList();
    },
    onDeviceDelete() {
      this.fetchDeviceList();
    },
  },

  mounted() {
    this.fetchDeviceList();
  },
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.content-filter {
  margin-top: -1rem;
}
.tab-filter {
  min-width: 200px;
  border-right: 2px solid #eaeaec;
  padding-right: 0.5rem;
}
.badge-circle {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 4px;

  text-align: center;

  font: 10pt Arial, sans-serif;
}
.p-viewer {
  float: right;
  margin-right: 10px;
  margin-top: -33px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
</style>
